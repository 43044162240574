<template>
  <table class="table is-bordered data-table sticky-header-table">
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column.name"
            @click="$emit('sort', column.name)"
            :class="column.class"
            :style="'width:' + column.width + ';' + 'cursor:pointer'"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <slot></slot>
    </table>
</template>

<script>
export default {
  props: ["columns", "sortKey", "sortOrder"],
  data() {
  return {
    isTableVisible: false,
  }
},

};
</script>

<style scoped>
.sticky-header-table /deep/ .sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}
</style>
<style scoped>
.table-container--visible .sticky-header-table /deep/ .sticky-header {
  position: sticky;
  top: 160px;
  z-index: 1;
  background-color: white;
}

</style>
