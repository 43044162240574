<style lang="scss">
.pagination {
  justify-content: flex-end !important;
  .page-stats {
    align-items: center;
    margin-right: 5px;
  }
  i {
    color: #3273dc !important;
  }
}
</style>
<template>
  <div class="row">
    <div class="col-sm-12 col-md-5">
      <div
        class="dataTables_info"
        id="m_table_1_info"
        role="status"
        aria-live="polite"
      >
        Showing
        {{
          pagination.current_page == 1
            ? 1
            : (pagination.current_page - 1) * pagination.per_page_data + 1
        }}
        to
        {{
          pagination.received_per_page_data != pagination.per_page_data
            ? (pagination.current_page - 1) * pagination.per_page_data +
              pagination.received_per_page_data
            : pagination.current_page * pagination.received_per_page_data
        }}
        of {{ pagination.total_records }} entries
      </div>
    </div>
    <div class="col-sm-12 col-md-7">
      <div
        class="dataTables_paginate paging_full_numbers"
        id="m_table_1_paginate"
      >
        <ul class="pagination">
          <li
            v-if="pagination.current_page > 1"
            class="paginate_button page-item first"
            id="m_table_1_first"
          >
            <a
              href="#"
              aria-controls="m_table_1"
              data-dt-idx="0"
              tabindex="0"
              class="page-link"
              @click="$emit('first')"
              ><i class="la la-angle-double-left"></i
            ></a>
          </li>
          <li
            v-if="pagination.current_page > 1"
            class="paginate_button page-item previous"
            id="m_table_1_previous"
          >
            <a
              href="#"
              aria-controls="m_table_1"
              data-dt-idx="1"
              tabindex="0"
              class="page-link"
              @click="$emit('previous')"
              ><i class="la la-angle-left"></i
            ></a>
          </li>
          <li
            class="paginate_button page-item "
            v-for="n in pagination.total_page"
            :key="n"
            :class="pagination.current_page == n ? 'active' : ''"
            v-show="
              pagination.current_page <= n
                ? n <= pagination.current_page + 3
                : n >= pagination.current_page - 3
            "
          >
            <a
              href="#"
              aria-controls="m_table_1"
              data-dt-idx="2"
              tabindex="0"
              class="page-link"
              @click="$emit('selected', n)"
              >{{ n }}</a
            >
          </li>
          <li
            v-if="pagination.current_page < pagination.total_page"
            class="paginate_button page-item next"
            id="m_table_1_next"
          >
            <a
              href="#"
              aria-controls="m_table_1"
              data-dt-idx="7"
              tabindex="0"
              class="page-link"
              @click="$emit('next')"
              ><i class="la la-angle-right"></i
            ></a>
          </li>
          <li
            v-if="pagination.current_page < pagination.total_page"
            class="paginate_button page-item last"
            id="m_table_1_last"
          >
            <a
              href="#"
              aria-controls="m_table_1"
              data-dt-idx="8"
              tabindex="0"
              class="page-link"
              @click="$emit('last')"
              ><i class="la la-angle-double-right"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pagination"]
};
</script>
