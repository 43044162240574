<template>
  <div class="row" id="client_location">
    <div class="col-md-12">
      <div class="card card-custom">
        <div class="card-body">
          <div class="row">
            <div class="col-md-2 pr-0">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Search By Job Title, Client Name"
                  class="form-control"
                  v-model="search_text"
                />
              </div>
            </div>
            <div class="col-md-2 pr-0" v-if="!currentUser.u.is_client">
              <div class="form-group">
                <select
                  v-model="serviceOutlet"
                  name=""
                  class="form-control"
                  id=""
                >
                  <option value="all">All Location</option>
                  <option
                    v-for="(citem, index) in getClients"
                    :key="index"
                    v-show="citem.is_mobile_fingerprinting"
                    :value="citem.id"
                  >
                    {{ citem.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-1 pl-2 pr-0">
              <div class="form-group">
                <select v-model="status" class="form-control">
                  <option value="all">All Status</option>
                  <option value="Open">Open</option>
                  <option value="Closed">Closed</option>
                  <!-- <option value="Refund">Refunded</option> -->
                </select>
              </div>
            </div>

            <div class="col-md-1">
              <button class="btn btn-outline-info" @click="search()">
                <i class="fa fa-search"></i>
              </button>
            </div>
            <!-- <div class="col-md-2" ></div> -->

            <div class="col-md-6 float-right">
              <button
                class="btn btn-info"
                @click="JobCreate()"
                style="float: right"
              >
                <i class="fa fa-plus"></i> Add Mobile Service
              </button>
            </div>
          </div>
          <div class="row" v-if="getJobList.length > 0 && !loadingData">
            <div class="col-md-12">
              <hr v-if="getJobList.length > 0 && !loadingData" />
              <div class="row" v-if="getJobList.length > 0 && !loadingData">
                <div class="col-md-12">
                  <v-table :columns="columns">
                    <tr v-for="(job, index) in getJobList" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{ job.title }}
                      </td>
                      <td
                        v-if="
                          job.schedule_date != null && job.schedule_date != ''
                        "
                      >
                        {{ job.schedule_date }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="temp_serviceOutlet == 'all'">
                        <b>
                          {{ job.location_name }}
                        </b>
                        <br />
                        {{ job.service_name }}
                      </td>

                      <td>{{ job.client_name }}</td>
                      <td>{{ job.client_phone_no }}</td>
                      <td>{{ job.payment_by }}</td>
                      <td>
                        <span
                          v-if="job.status != 'Open'"
                          class="badge badge-danger"
                          >{{ job.status }}</span
                        >
                        <span
                          v-if="job.status == 'Open'"
                          class="badge badge-success"
                          >{{ job.status }}</span
                        >
                      </td>
                      <td class="text-center">
                        <button
                          @click="editJob(job)"
                          class="btn btn-info btn-sm mr-3"
                        >
                          Edit
                        </button>

                        <button
                          @click="viewdetails(job)"
                          class="btn btn-success btn-sm"
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                    <!-- </tbody>
        <tbody v-if="!loadingData && getJobList && getJobList.length < 1">
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>
        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody> -->
                  </v-table>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="getJobList.length < 1 && !loadingData">
            <div class="col-md-12 text-center">
              <h4>no job(s) found</h4>
            </div>
          </div>
          <div class="row" v-if="loadingData">
            <div class="col-md-12 text-center">
              <v-data-loader></v-data-loader>
            </div>
          </div>
          <hr v-if="pagination.total_page > 1" />
          <vl-pagination
            v-on:selected="selectedPage"
            v-on:previous="previousPage"
            v-on:first="firstPage"
            v-on:last="lastPage"
            v-on:next="nextPage"
            v-if="pagination.total_page > 1"
            :pagination="pagination"
          ></vl-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_CLIENT_JOB_LIST,
  FETCH_CLIENTS,
} from "@/core/services/store/actions.type";
import vTable from "@/includes/table/Datatable";
import vDataLoader from "@/components/frontend/spinner.vue";
import vlPagination from "@/includes/table/Pagination";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // loadingData: false,
      status: "all",
      serviceOutlet: "all",
      temp_serviceOutlet: "all",
      getJobList: [],
      search_text: "",
      offset: 0,
      limit: 15,
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15,
      },
      columns: [
        {
          name: "S. No.",
          width: "",
          label: "S. No.",
          class: "",
        },
        {
          name: "Job Title",
          width: "",
          label: "Job Title",
          class: "",
        },
        {
          name: "Schedule Date",
          width: "",
          label: "Schedule Date",
          class: "",
        },
        {
          name: "Location/Service",
          width: "",
          label: "Location/Service",
          class: "",
        },
        {
          name: "Client Name",
          width: "",
          label: "Client Name",
          class: "",
        },
        {
          name: "Client Phone No",
          width: "",
          label: "Client Phone No",
          class: "",
        },
        {
          name: "Payment By",
          width: "",
          label: "Payment By",
          class: "",
        },

        {
          name: "Status",
          width: "",
          label: "Status",
          class: "",
        },
        {
          name: "Action",
          width: "",
          label: "Action",
          class: "text-center",
        },
      ],

      url: "",
      loadingData: false,
      client_slug: this.$route.params.client_slug,
      msg: "",
      // clientJobs: [],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Mobile Service",
          route: "",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(FETCH_CLIENTS);
    this.serviceOutlet = this.currentUser.u.is_client ? "self" : "all";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.status = "all";
    this.jobData();
    this.tablereset();
  },

  components: {
    vDataLoader,
    vlPagination,
    vTable,
  },
  computed: {
    ...mapGetters(["currentUser", "getClients"]),
  },
  methods: {
    search() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15,
      };
      this.jobData();
      this.tablereset();
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      this.jobData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.jobData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.jobData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.jobData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.jobData();
    },
    jobData() {
      this.getJobList = [];

      this.loadingData = true;
      var data = {
        service_outlet: this.serviceOutlet,
        user_type: this.currentUser.u.is_client ? "c" : "sp",
        status: this.status,
        page: this.pagination.current_page,
        search_text: this.search_text,
      };
      this.$store
        .dispatch(FETCH_CLIENT_JOB_LIST, data)
        .then((res) => {
          this.loadingData = false;
          this.getJobList = res.data;
          this.pagination.total_records = res.total_record;
          this.pagination.total_page = res.page_range;
          this.pagination.received_per_page_data = res.data.length;
          this.pagination.per_page_data = 15;
        })
        .catch((err) => {
          this.loadingData = false;
          this.msg = err.error;
        });
    },
    tablereset() {
      this.temp_serviceOutlet = this.serviceOutlet;
      if (this.serviceOutlet == "all") {
        this.columns = [
          {
            name: "S. No.",
            width: "",
            label: "S. No.",
            class: "",
          },
          {
            name: "Job Title",
            width: "",
            label: "Job Title",
            class: "",
          },
          {
            name: "Schedule Date",
            width: "",
            label: "Schedule Date",
            class: "",
          },
          {
            name: "Location/Service",
            width: "",
            label: "Location/Service",
            class: "",
          },
          {
            name: "Client Name",
            width: "",
            label: "Client Name",
            class: "",
          },
          {
            name: "Client Phone No",
            width: "",
            label: "Client Phone No",
            class: "",
          },
          {
            name: "Payment By",
            width: "",
            label: "Payment By",
            class: "",
          },

          {
            name: "Status",
            width: "",
            label: "Status",
            class: "",
          },
          {
            name: "Action",
            width: "",
            label: "Action",
            class: "text-center",
          },
        ];
      } else {
        this.columns = [
          {
            name: "S. No.",
            width: "",
            label: "S. No.",
            class: "",
          },
          {
            name: "Job Title",
            width: "",
            label: "Job Title",
            class: "",
          },
          {
            name: "Schedule Date",
            width: "",
            label: "Schedule Date",
            class: "",
          },
          {
            name: "Client Name",
            width: "",
            label: "Client Name",
            class: "",
          },
          {
            name: "Client Phone No",
            width: "",
            label: "Client Phone No",
            class: "",
          },
          {
            name: "Payment By",
            width: "",
            label: "Payment By",
            class: "",
          },

          {
            name: "Status",
            width: "",
            label: "Status",
            class: "",
          },
          {
            name: "Action",
            width: "",
            label: "Action",
            class: "text-center",
          },
        ];
      }
    },
    JobCreate() {
      if (this.currentUser.u.is_client) {
        this.$router.push({
          name: "client.job.form",
          params: {
            client_slug: this.client_slug,
            job_id: 0,
            client_id: this.currentUser.c.id,
          },
        });
      } else {
        // if (this.serviceOutlet == "all") {
        //   this.$toastr.e("Please select a location to create new job");
        // } else {
          this.$router.push({
            name: "client.job.form",
            params: {
              client_slug: this.client_slug,
              job_id: 0,
              client_id: this.serviceOutlet,
            },
          });
        // }
      }
    },
    // advSearch() {
    //   this.search_text = "";
    //   this.search();
    // },
    editJob(job) {
      this.$router.push({
        name: "client.job.form",
        params: {
          client_slug: this.client_slug,
          job_id: job.id,
          client_id: job.client,
        },
      });
    },

    viewdetails(job) {
      this.$router.push({
        name: "client.job.details",
        params: {
          client_slug: this.client_slug,
          job_id: job.id,
          client_id: job.client,
        },
      });
    },
  },
};
</script>
